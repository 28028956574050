<template>
<div>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar flat dark dense color="primary">
        <v-toolbar-title>Índices Técnicos de Produção</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed data-cy="novo" class="lighten-1" @click="abrirBuscaProdutos">Novo Insumo</v-btn>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-row dense>
          <v-col cols="2">
            <v-text-field ref="input" flat filled rounded dense single-line hide-details label="Código" v-model="produto.codigo" readonly></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field ref="input" flat filled rounded dense single-line hide-details label="Descrição" v-model="produto.descricao" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 400px" class="pt-1">
        <v-data-table :options.sync="options" hide-default-footer :headers="headers" :items="insumos" :loading="loading">
          <template v-slot:item="{item}">
            <tr>
              <td class="text-center">{{ item.produto.codigo }}</td>
              <td>{{ item.produto.descricao }}</td>
              <td class="text-center">{{ item.produto.unidade }}</td>
              <td class="text-center">{{ item.quantidade | formatarValor }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item data-cy="editar" @click="editarInsumo(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item data-cy="remover" @click="abrirDialogExcluirInsumo(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fechar" text :disabled="salvando">Cancelar</v-btn>
        <v-btn color="primary" :loading="salvando" :disabled="salvando" @click="salvar">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogQuantidade" max-width="600px" persistent scrollable>
    <v-card>
      <v-toolbar flat dark dense color="primary">
        <v-toolbar-title>Quantidade do Insumo</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="pt-1">
        <v-row dense>
          <v-col cols="2">
            <v-text-field ref="insumo_codigo" flat filled rounded dense single-line hide-details label="Código" v-model="insumo.produto.codigo" readonly></v-text-field>
          </v-col>
          <v-col cols="7">
            <v-text-field ref="insumo_descricao" flat filled rounded dense single-line hide-details label="Descrição" v-model="insumo.produto.descricao" readonly></v-text-field>
          </v-col>
          <v-col cols="3">
             <as-campo-valor
              v-model="insumo.quantidade"
              name="insumo_quantidade"
              label="Quantidade"
              ref="insumo_quantidade"
              :decimais="2"
              :rules="[rules.valorMaiorQueZero]"
              @keypress.native.enter="salvarInsumo"
              flat filled rounded dense single-line hide-details
            ></as-campo-valor>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogQuantidade=false" text>Cancelar</v-btn>
        <v-btn color="primary" @click="salvarInsumo">Salvar Insumo</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogExcluirInsumo" max-width="600px" persistent scrollable>
    <v-card>
      <v-toolbar flat dense dark color="primary">
        <v-toolbar-title>Excluir Insumo</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="pt-1">
        O insumo será excluído do índice de produção deste produto
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogExcluirInsumo=false" text>Cancelar</v-btn>
        <v-btn color="error" @click="excluirInsumo">Excluir Insumo</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <dialog-buscar-produto ref="dialogBuscarProduto" @produtoSelecionado="selecionarProduto" :produto-oculto="produto._id"></dialog-buscar-produto>
</div>
</template>

<script>
import produtosApi from '@/services/produtosApi';
import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'IndicesTecnicos',
  components:{
    DialogBuscarProduto
  },
  props: {
    produto: {
      type: Object,
      default: null,
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      salvando: false,
      insumos: [],
      headers: [
        { text: 'Código', value: 'codigo', sortable: false, width: '10%' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: '55%' },
        { text: 'Unidade', value: 'valor', align: 'center', sortable: false, width: '10%' },
        { text: 'Quantidade', value: 'estoque', align: 'center', sortable: false, width: '15%' },
        { text: 'Ações', value: 'name', align:'center',  sortable: false, width: '10%' }
      ],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      exibirDialogBuscaProduto: false,
      insumo: {
        produto: {
          codigo: '',
          descricao: '',
          _id: '',
          unidade: ''
        },
        quantidade: 0
      },
      dialogQuantidade: false,
      rules: {...regras},
      insumoIndex: -1,
      dialogExcluirInsumo: false,
    }
  },
  methods: {
    abrir () {
      this.loading = true;
      this.dialog = true;
      setTimeout(() => {
        this.insumos = this.produto.insumos;
      }, 100);
      this.loading = false;
    },
    fechar(){
      this.loading = false;
      this.insumo = {
        produto: {
          codigo: '',
          descricao: '',
          _id: '',
          unidade: ''
        },
        quantidade: 0
      };
      this.insumos = [];
      this.dialog = false;
      this.$emit('fechar');
    },
    abrirBuscaProdutos () {
      this.$refs.dialogBuscarProduto.abrir();
    },
    selecionarProduto (produto_selecionado) {
      this.insumo = {
        produto: {
          _id: produto_selecionado._id,
          codigo: produto_selecionado.codigo,
          descricao: produto_selecionado.descricao,
          unidade: produto_selecionado.unidade
        },
        quantidade: 0,
      };
      this.dialogQuantidade = true;
      setTimeout(() =>{
        this.$refs.insumo_quantidade.focus()
      }, 100);
    },
    salvarInsumo () {
      if (this.insumo.quantidade > 0) {

        let novo_insumo = {
          produto: this.insumo.produto,
          quantidade: this.insumo.quantidade
        };

        if (this.insumoIndex > -1) { //é uma edição
          this.insumos.splice(this.insumoIndex, 1, novo_insumo);
          this.insumoIndex = -1;
        } else {
          this.insumos.push(novo_insumo);
        }
        this.insumo = {
          produto: {
            codigo: '',
            descricao: '',
            _id: '',
            unidade: ''
          },
          quantidade: 0
        };
        this.dialogQuantidade = false;
      }
    },
    editarInsumo (insumo_selecionado) {
      this.insumoIndex = this.insumos.indexOf(insumo_selecionado);
      this.insumo = {...insumo_selecionado};
      this.dialogQuantidade = true;
      setTimeout(() => {
        this.$refs.insumo_quantidade.focus()
      }, 100);
    },
    abrirDialogExcluirInsumo (insumo_selecionado) {
      this.insumoIndex = this.insumos.indexOf(insumo_selecionado);
      this.insumo = {...insumo_selecionado};
      this.dialogExcluirInsumo = true;
    },
    excluirInsumo () {
      this.insumos.splice(this.insumoIndex, 1);
      this.insumo = {
        produto: {
          codigo: '',
          descricao: '',
          _id: '',
          unidade: ''
        },
        quantidade: 0
      };
      this.dialogExcluirInsumo = false;
    },
    async salvar () {
      try {
        this.salvando = true;
        let insumos = await produtosApi.salvarInsumos(this.produto._id, this.insumos);
        this.insumo = {
          produto: {
            codigo: '',
            descricao: '',
            _id: '',
            unidade: ''
          },
          quantidade: 0
        };
        this.$store.commit('produto/atualizar', this.produto);
        this.insumos = [];
        this.dialog = false;
      } catch (erro) {
        console.log(erro);
        throw erro;
      } finally {
        this.salvando = false;
      }
    }
  },
}
</script>

<style>
  
</style>