<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px" scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Ajustar Estoque</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="form_valid">
            <v-row dense>
              <v-col cols="2">
                <v-text-field v-model="produto.codigo" name="codigo" label="Código" id="codigo" readonly></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="produto.descricao" name="descricao" label="Descrição" id="descricao" readonly>></v-text-field>
              </v-col>
              <v-col cols="3">
                <as-campo-valor v-model="produto.estoque" name="estoque" label="Estoque" id="estoque" readonly>></as-campo-valor>
              </v-col>
              <v-col cols="3">
                <as-campo-valor v-model="ajuste" name="ajuste" label="Novo Estoque" ref="ajuste" :rules="[rules.valorPositivo]" @keydown.native.enter="ajustarEstoque"></as-campo-valor>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="fechar" text>Cancelar</v-btn>
          <v-btn @click="ajustarEstoque" color="primary" :disabled="!form_valid">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import * as regras from '@/util/validacaoFormulario';

  export default {
  name: 'DialogAjusteEstoque',
  data () {
    return {
      dialog: false,
      ajuste: 0,
      rules: {...regras},
      form_valid: true
    }
  },
  computed: {
    ...mapState('produto', {
      produto: 'itemAtual',
      carregando: 'carregando',
    })
  },
  methods: {
    abrir () {
      this.dialog = true;
      setTimeout (() => {
        this.$refs.ajuste.focus();
      }, 500);
    },
    fechar () {
      this.ajuste = 0;
      this.dialog = false;
    },
    async ajustarEstoque () {
      if (this.$refs.form.validate()) {
        let ajuste = {
          'ajuste_estoque': this.ajuste
        };
        await this.$store.dispatch('produto/ajustarEstoque', ajuste);
        this.fechar();
      }
    }
  },
  }
</script>

<style>
  
</style>