<template>
  <div>
    <v-container fluid>
      <v-card>  
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrarProdutos($event)" max-width="300px" prepend-inner-icon="search" :label="constantes.FILTRAR_PRODUTOS"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="novoProduto()" data-cy="novo">{{constantes.BOTAO_NOVO_PRODUTO}}</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="produtos" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td class="text-center">{{ props.item.codigo }}</td>
              <td>{{ props.item.descricao }}</td>
              <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
              <td class="text-center">{{ props.item.estoque | formatarValor }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item @click="ajustarEstoque(props.item)" data-cy="editar">
                      <v-list-item-icon><v-icon>mdi-archive-alert</v-icon></v-list-item-icon>
                      <v-list-item-title>Ajustar Estoque</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="editarProduto(props.item)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item  @click="remover(props.item)" data-cy="remover">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="abrirIndices(props.item)" data-cy="remover" v-if="props.item.ippt=='P'">
                      <v-list-item-icon><v-icon>mdi-format-list-bulleted-square</v-icon></v-list-item-icon>
                      <v-list-item-title>Índices Técnicos</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <indices-tecnicos ref="indice" :produto="produto"></indices-tecnicos>
    <dialog-ajuste-estoque ref="ajuste_estoque"></dialog-ajuste-estoque>
  </div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';
  import IndicesTecnicos from './IndicesTecnicos.vue';
  import DialogAjusteEstoque from './DialogAjusteEstoque.vue';

  export default {
    components: {
      IndicesTecnicos,
      DialogAjusteEstoque,
    },
    data () {
      return {
        headers: [
          { text: 'Código', value: 'codigo', sortable: false, align: 'center', width: '10%'},
          { text: 'Descrição', value: 'descricao', sortable: false, width: '65%' },
          { text: 'Valor', value: 'valor', align: 'center', sortable: false, width: '10%' },
          { text: 'Estoque', value: 'estoque', align: 'center', sortable: false, width: '10%' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false, width: '5%' }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        },
        produto: {},
      }
    },

    watch: {
      options: {
        handler () {
          this.carregar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('produto', {
        produtos: 'itens',
        carregando: 'carregando',
        total: 'totalProdutos'
      })
    },

    methods: {
      novoProduto(){
        this.$store.commit('produto/novo');
        
        let empresa = this.$store.state.empresaAtual;
        if(empresa.plano && empresa.plano === 'nfe'){
          this.$router.push('/cadastros/produtosNfe/novo');
        }
        else{
          this.$router.push('/cadastros/produtos/novo');
        }
      },

      editarProduto(produto){
        this.$store.commit('produto/editar', produto);
        
        let empresa = this.$store.state.empresaAtual;
        if(empresa.plano && empresa.plano === 'nfe'){
          this.$router.push({name: 'formularioProdutoNfeEditar'});
        }
        else{
          this.$router.push({name: 'formularioProdutoEditar'});
        }
      },

      abrirIndices (produto) {
        this.produto = produto;
        this.$refs.indice.abrir();
      },

      carregar(){
        this.$store.dispatch('produto/carregar', this.options); 
      },

      limparBusca(){
        this.carregar();
      },

      filtrarProdutos(event){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('produto/carregar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300)
      },

      remover(item){
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('produto/remover', item);
          }
        });
      },

      ajustarEstoque (produto_selecionado) {
        this.$store.commit('produto/setItemAtual', produto_selecionado);
        this.$refs.ajuste_estoque.abrir();
      }
    },
  }
</script>